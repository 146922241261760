$bb-blue: #38A2E8;
$text-color: #888888;

@mixin box-shadow($e) {
  -webkit-box-shadow: $e;
  -moz-box-shadow: $e;
  box-shadow: $e;
}

@mixin transition($e) {
  -webkit-transition: $e;
  -moz-transition: $e;
  transition: $e;
}

@mixin transform($e) {
  -webkit-transform: $e;
  -moz-transform: $e;
  transform: $e;
}

.navbar {
  @include transition(.5s ease-in-out);
}

.bb-navbar {
    background-color: white;
    //@include box-shadow(0px 1px 5px 0px #000000);
    .navbar-toggler {
        border-color: $bb-blue;
    }

    .logo-img {
        max-height: 8vh;
        margin-left: 10px;
    }

    .logo-center {
        /*position: absolute;
        display: block;
        left: 48%;
        margin-left: -50px !important;*/
        /*transform: translateX(-50%);
        left: 50%;
        position: absolute;
        vertical-align: middle;
        display: inline !important;*/
        max-height: 6vh;
    }

    .center {
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        align-self: center;
    }

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $bb-blue;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 400;
        text-transform: uppercase;
        @include transition(.3s ease-in-out);

        a {
            color: $bb-blue;

            &:hover {
                color: $text-color;
                text-decoration: none;
            }
        }
    }

    .dropdown-link {
        color: #F88E28 !important;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .home-link {
        color: #F88E28
    }

    .dropdown-menu {
        left: auto !important;
    }
}